const QUAN_LY_TAI_KHOAN = "/quan-ly-tai-khoan"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  DANG_NHAP: "/dang-nhap",
  DANG_KY: "/dang-ky",
  DOI_MAT_KHAU: "/doi-mat-khau",
  PHAN_ANH: "/phan-anh",
  ACCOUNT: "/tai-khoan",
  DVC: "/dvc",

  //ANONYMOUS

  // ADMIN
  QUAN_LY_TAI_KHOAN: "/quan-ly-tai-khoan",
  QUAN_LY_ADMIN: `${QUAN_LY_TAI_KHOAN}/quan-ly-admin`,
}
export default ROUTER
